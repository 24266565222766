import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin } from "@/website-editor/custom-fields/margin";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import { CheckIcon } from "@radix-ui/react-icons";
import { MinusIcon } from "lucide-react";
import Image from "next/image";
import { Button as ButtonComponent } from "@/components/ui/button";
import PiLink from "@/components/PiLink";
import { nanoid } from "nanoid/non-secure";
import FieldRenderer from "@/website-editor/blocks/price-comparison-section/TierField";
import { Icon, iconOptions } from "@/website-editor/custom-fields/icon";
import React from "react";

function parsePrice(price: string): {
  value: number;
  prefix: string;
  suffix: string;
} {
  return {
    value: +price.replace(/[^0-9.]/g, ""),
    prefix: /^([^0-9]*)/.exec(price)?.[0] ?? "",
    suffix: /([^0-9]*)$/.exec(price)?.[0] ?? "",
  };
}

export type PriceComparisonSectionProps = {
  supertitle: string;
  title: string;
  description: string;
  sectionId?: string;
  featuresGroups: Array<{
    name: string;
    description?: string;
    icon?: string;
    features: Array<{
      name: string;
      description?: string;
      tiers: Array<{
        tierId: string;
        value: string;
      }>;
    }>;
  }>;
  tiers: Array<{
    id: string;
    buttonText: string;
    name: string;
    description?: string;
    href?: string;
    priceMonthly: string;
    priceAnnual: string;
    mostPopular: boolean;
  }>;
  theme: Theme;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  paddingTop: Padding;
  paddingBottom: Padding;
  paddingLeft: Margin;
  paddingRight: Margin;
};

const tier1Id = `Tier-${nanoid()}`;
const tier2Id = `Tier-${nanoid()}`;
const tier3Id = `Tier-${nanoid()}`;
export const PriceComparisonSection = {
  displayName: "Fiyat Karşılaştırma Bölümü",
  fields: {
    sectionId: SectionIdField,
    supertitle: {
      type: "textarea",
      label: "Üst Başlık",
    },
    title: {
      type: "richtextarea",
      label: "Başlık",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    tiers: {
      type: "array",
      label: "Tierlar",
      getItemSummary: (item, idx) => item.name || `Tier ${(idx ?? 0) + 1}`,
      getDefaultItemProps: () => ({
        id: `Tier-${nanoid()}`,
        name: "Tier",
        href: "",
        priceMonthly: "",
        priceAnnual: "",
        mostPopular: false,
        buttonText: "",
      }),
      arrayFields: {
        id: {
          type: "custom",
          label: "ID",
          render: () => <></>,
        },
        name: {
          type: "text",
          label: "İsim",
        },
        description: {
          type: "richtextarea",
          label: "Açıklama",
        },
        priceMonthly: {
          type: "text",
          label: "Aylık Fiyat",
        },
        priceAnnual: {
          type: "text",
          label: "Yıllık Fiyat",
        },
        mostPopular: {
          type: "radio",
          label: "En Popüler",
          options: [
            {
              value: false,
              label: "Hayır",
            },
            {
              value: true,
              label: "Evet",
            },
          ],
        },
        buttonText: {
          type: "text",
          label: "Buton Metni",
        },
        href: {
          type: "text",
          label: "Buton Linki",
        },
      },
    },
    featuresGroups: {
      type: "array",
      label: "Özellik Grupları",
      getItemSummary: (item, idx) => item.name || `Grup ${(idx ?? 0) + 1}`,
      arrayFields: {
        name: {
          type: "text",
          label: "Grup Adı",
        },
        icon: {
          type: "select",
          label: "İkon",
          options: iconOptions,
        },
        features: {
          type: "array",
          label: "Özellikler",
          getItemSummary: (item, idx) =>
            item.name || `Özellik ${(idx ?? 0) + 1}`,
          arrayFields: {
            name: {
              type: "richtextarea",
              label: "Özellik Adı",
            },
            description: {
              type: "richtextarea",
              label: "Özellik Açıklaması",
            },
            tiers: {
              type: "custom",
              label: "Tierlar",
              render: (props) => {
                return <FieldRenderer {...props} />;
              },
              arrayFields: {
                tierId: {
                  type: "text",
                  label: "Tier ID",
                  readonly: true,
                },
                value: {
                  type: "text",
                  label: "Değer",
                },
              },
            },
          },
        },
      },
      getDefaultItemProps: () => ({
        name: "Özellik Grubu",
        features: [],
      }),
    },
    theme: ThemeField,
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  defaultProps: {
    supertitle: "Ücretlendirme",
    title: "Plans for teams of all sizes",
    description:
      "Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.",
    sectionId: "",
    theme: "light",
    featuresGroups: [
      {
        icon: "AcademicCapIcon",
        name: "Ödemeler",
        features: [
          {
            name: "Kredi Kartı ile Online Ödeme",
            description: "İyzico altyapısı ile güvenli ödeme",
            tiers: [
              { tierId: tier1Id, value: "true" },
              { tierId: tier2Id, value: "true" },
              { tierId: tier3Id, value: "true" },
            ],
          },
        ],
      },
    ],
    tiers: [
      {
        id: tier1Id,
        name: "Ücretsiz",
        href: "#",
        priceMonthly: "0₺",
        priceAnnual: "0₺",
        mostPopular: false,
        buttonText: "Hemen Başla",
      },
      {
        id: tier2Id,
        name: "Temel",
        href: "#",
        priceMonthly: "199₺",
        priceAnnual: "1999₺",
        mostPopular: true,
        buttonText: "Hemen Başla",
      },
      {
        id: tier3Id,
        name: "Pro",
        href: "#",
        priceMonthly: "399₺",
        priceAnnual: "3999₺",
        mostPopular: false,
        buttonText: "Hemen Başla",
      },
    ],
    backgroundImage: null,
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingTop: "2xl",
    paddingBottom: "2xl",
  },
  render: ({
    puck: { renderDropZone },
    supertitle,
    title,
    tiers,
    sectionId,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    description,
    featuresGroups,
    backgroundImage,
    theme,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "overflow-clip isolate bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div
          className={cn(
            "mx-auto max-w-7xl",
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
          )}
        >
          {backgroundImage && (
            <Image
              src={backgroundImage?.url ?? ""}
              alt={backgroundImage?.alt ?? ""}
              width={1080}
              height={1080}
              unoptimized={!backgroundImage?.url.includes(".pi.education/")}
              className={cn(
                "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
                mutedTextColorVariants({ theme }),
              )}
            />
          )}
          {supertitle ? (
            <h2 className="mx-auto max-w-4xl text-center text-base font-semibold leading-7 text-primary">
              {supertitle}
            </h2>
          ) : null}

          {title ? (
            <div
              className={cn(
                "mt-2 mx-auto max-w-3xl text-center text-4xl font-bold tracking-tight sm:text-5xl",
                textColorVariants({ theme }),
              )}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : null}

          {description ? (
            <div
              className={cn(
                "mx-auto mt-6 max-w-2xl text-center text-lg leading-8",
                mutedTextColorVariants({ theme }),
              )}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : null}

          <div className="isolate mt-12 flow-root sm:mt-16 lg:mt-20">
            <div className="relative -mx-4 md:-mx-8">
              {tiers.map((tier, idx) =>
                tier.mostPopular ? (
                  <div
                    key={tier.id}
                    className="absolute inset-0 -z-10 flex md:inset-x-4"
                    style={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      "--tier-length": tiers.length,
                      "--tier-index": idx,
                    }}
                  >
                    <div
                      className="ml-[calc(100%*var(--tier-index)/var(--tier-length))] flex w-[calc(100%/var(--tier-length))] md:ml-[calc(100%*(var(--tier-index)+1)/(var(--tier-length)+1))] md:w-[calc(100%/(var(--tier-length)+1))] md:px-4"
                      aria-hidden="true"
                    >
                      <div className="w-full rounded-t-xl border-x border-t border-gray-200 bg-gray-50" />
                    </div>
                  </div>
                ) : null,
              )}

              <table className="w-full table-fixed border-separate text-left md:border-spacing-x-8">
                <thead>
                  <tr>
                    <td className="hidden md:table-cell" />
                    {tiers.map((tier) => (
                      <th
                        key={tier.id}
                        scope="col"
                        className="px-2 pt-6 md:px-6 xl:px-8 xl:pt-8"
                      >
                        <div
                          className={cn(
                            "text-xs sm:text-sm font-semibold leading-7",
                            textColorVariants({ theme }),
                          )}
                          dangerouslySetInnerHTML={{ __html: tier.name }}
                        />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="hidden md:table-cell" scope="row" />

                    {tiers.map((tier) => {
                      const monthlyPrice = parsePrice(tier.priceMonthly);

                      return (
                        <td
                          key={tier.id}
                          className="px-2 pt-2 align-baseline md:px-6 xl:px-8"
                        >
                          <div className="flex items-baseline gap-x-1">
                            <span
                              className={cn(
                                "text-xl sm:text-4xl font-bold",
                                textColorVariants({ theme }),
                              )}
                            >
                              {tier.priceMonthly}
                            </span>
                            <span
                              className={cn(
                                "text-sm font-normal",
                                mutedTextColorVariants({ theme }),
                              )}
                            >
                              /ay
                            </span>
                          </div>
                          {tier.priceAnnual !== "" && (
                            <div
                              className={cn("mt-2 flex items-baseline", {
                                hidden: monthlyPrice.value === 0,
                              })}
                            >
                              <span
                                className={cn(
                                  "hidden sm:inline text-sm font-normal line-through mr-1",
                                  mutedTextColorVariants({ theme }),
                                )}
                              >
                                {monthlyPrice.prefix}
                                {monthlyPrice.value * 12}
                                {monthlyPrice.suffix}
                              </span>
                              <span
                                className={cn(
                                  "text-xs sm:text-sm md:text-base font-semibold",
                                  textColorVariants({ theme }),
                                )}
                              >
                                {tier.priceAnnual}
                              </span>
                              <span
                                className={cn(
                                  "text-xs sm:text-sm font-normal",
                                  mutedTextColorVariants({ theme }),
                                )}
                              >
                                /yıl
                              </span>
                            </div>
                          )}
                          {tier.description && (
                            <div
                              className={cn(
                                "mt-2 text-xs sm:text-sm md:text-base font-normal",
                                mutedTextColorVariants({ theme }),
                              )}
                              dangerouslySetInnerHTML={{
                                __html: tier.description,
                              }}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th
                      className={cn(
                        "sticky top-16 z-10 hidden md:table-cell bg-background",
                      )}
                      scope="row"
                    />

                    {tiers.map((tier) => {
                      return (
                        <td
                          key={tier.id}
                          className={cn(
                            "sticky top-16 z-10 sm:px-2 pt-2 align-bottom md:px-6 xl:px-8 bg-background",
                            {
                              "border-x border-gray-200 bg-gray-50":
                                tier.mostPopular,
                            },
                          )}
                        >
                          <div
                            className={cn(
                              "text-xs sm:text-sm font-semibold leading-7",
                              textColorVariants({ theme }),
                            )}
                          >
                            {tier.name}
                          </div>
                          {tier.buttonText && (
                            <ButtonComponent
                              asChild
                              variant={tier.mostPopular ? "default" : "outline"}
                              size={"lg"}
                              className={cn("w-full mt-2 py-2 px-3")}
                            >
                              <PiLink href={tier.href ? tier.href : "#"}>
                                {tier.buttonText}
                              </PiLink>
                            </ButtonComponent>
                          )}
                          {renderDropZone({
                            zone: tier.id,
                          })}
                        </td>
                      );
                    })}
                  </tr>

                  {featuresGroups.map((group, sectionIdx) => (
                    <React.Fragment key={`${group.name}-${sectionIdx}`}>
                      <tr>
                        <th
                          scope="colgroup"
                          colSpan={tiers.length}
                          className={cn(
                            "px-2 sm:px-0 pb-4 text-sm bg-background md:bg-transparent font-bold leading-6 text-primary",
                            sectionIdx === 0
                              ? "pt-8"
                              : "pt-16 border-t md:border-t-0",
                          )}
                        >
                          <Icon
                            icon={group.icon || null}
                            className="mr-2 inline size-3 sm:size-4"
                          />
                          {group.name}
                          <div className="absolute inset-x-2 mt-4 h-px bg-gray-900/10 md:inset-x-8" />
                        </th>
                        <td className="hidden md:table-cell" />
                      </tr>
                      {group.features.map((feature, idx) => (
                        <React.Fragment key={idx}>
                          <tr className="md:hidden">
                            <th
                              scope="colgroup"
                              colSpan={tiers.length}
                              className={cn(
                                "px-2 py-4 text-sm font-semibold border-b leading-6 text-primary bg-background",
                                {
                                  "border-t": idx !== 0,
                                },
                              )}
                            >
                              <span
                                className="block text-sm font-semibold leading-6"
                                dangerouslySetInnerHTML={{
                                  __html: feature.name,
                                }}
                              />
                              {feature.description && (
                                <span
                                  className={cn(
                                    "text-xs font-normal",
                                    mutedTextColorVariants({ theme }),
                                  )}
                                  dangerouslySetInnerHTML={{
                                    __html: feature.description,
                                  }}
                                />
                              )}
                            </th>
                          </tr>
                          <tr key={idx}>
                            <th
                              scope="row"
                              className={cn(
                                "py-4 hidden md:table-cell",
                                textColorVariants({ theme }),
                              )}
                            >
                              <span
                                className="block text-sm font-semibold leading-6"
                                dangerouslySetInnerHTML={{
                                  __html: feature.name,
                                }}
                              />
                              {feature.description && (
                                <span
                                  className={cn(
                                    "text-xs font-normal",
                                    mutedTextColorVariants({ theme }),
                                  )}
                                  dangerouslySetInnerHTML={{
                                    __html: feature.description,
                                  }}
                                />
                              )}
                              <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                            </th>
                            {tiers.map((tier) => {
                              let matchingTier = null;
                              if (Array.isArray(feature.tiers)) {
                                matchingTier = feature.tiers.find(
                                  (t) => t.tierId === tier.id,
                                );
                              }

                              return (
                                <td
                                  key={tier.id}
                                  className="px-2 py-4 md:px-6 xl:px-8"
                                >
                                  {matchingTier &&
                                    (matchingTier.value === "true" ? (
                                      <CheckIcon
                                        className="mx-auto size-5 text-primary"
                                        aria-hidden="true"
                                      />
                                    ) : matchingTier.value === "false" ? (
                                      <MinusIcon
                                        className={cn(
                                          "mx-auto size-5",
                                          textColorVariants({ theme }),
                                        )}
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <div
                                        className={cn(
                                          "text-center text-sm leading-6",
                                          textColorVariants({ theme }),
                                        )}
                                      >
                                        {matchingTier.value}
                                      </div>
                                    ))}
                                </td>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  },
} as ComponentConfig<PriceComparisonSectionProps>;
